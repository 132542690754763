import type {SVGProps} from 'react';
export const CallIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
		<g
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			clipPath="url(#a)"
		>
			<path d="M4.5 18.5h-.8a3 3 0 0 1-3-3v-3a3 3 0 0 1 3-3h.8a.8.8 0 0 1 .8.8v7.4a.7.7 0 0 1-.8.8Zm15.8 0h-.8a.7.7 0 0 1-.8-.8v-7.4a.7.7 0 0 1 .8-.8h.8a3 3 0 0 1 3 3v3a3 3 0 0 1-3 3Z" />
			<path d="M3.8 9.5A8.2 8.2 0 0 1 12 1.2v0a8.2 8.2 0 0 1 8.3 8.3M15 22.3h2.3a3 3 0 0 0 3-3v-.8" />
			<path d="M13.5 23.8H12a1.5 1.5 0 1 1 0-3h1.5a1.5 1.5 0 1 1 0 3ZM9 16a4.5 4.5 0 0 0 6 0m-6-5.5v1.3m6-1.3v1.3" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="currentColor" d="M0 .5h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
