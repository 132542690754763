import {Suspense} from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import {Container} from '@/modules/foundation/components/container';
import type {KeyedArray} from '@/modules/foundation/shared/types';

import {DesktopNav} from './DesktopNav';
import type {DropdownType} from './Dropdown';
import {MobileNav} from './MobileNav';
import type {NavLinkType} from './NavLink';

export type NavigationType = KeyedArray<NavLinkType | DropdownType>;

type NavbarProps = {
	navigation: NavigationType;
};

const FRONT_PAGE = 'Forside';

export const Navbar = ({navigation}: NavbarProps) => {
	return (
		<Container
			as="header"
			className={clsx('relative', 'z-50', 'bg-primary', 'text-white', 'font-display')}
		>
			<div className={clsx('flex', 'h-20', 'items-center', 'justify-between', 'gap-4')}>
				<Link href="/">
					<span className={clsx('sr-only')}>{FRONT_PAGE}</span>
					<Image aria-hidden src="/selvklart-logo.svg" alt="" width={160} height={52} />
				</Link>
				{navigation ? (
					<Suspense>
						<DesktopNav navigation={navigation} />
						<MobileNav navigation={navigation} />
					</Suspense>
				) : null}
			</div>
		</Container>
	);
};
