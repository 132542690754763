import {linkFragmentToHref} from '@/modules/foundation/sanity/link/query';

import type {FooterProps} from '../../components/Footer';
import type {SiteSettingsFragment} from '../query';

export const footerPropsFromSiteSettingsFragment = (
	siteSettings: SiteSettingsFragment,
): FooterProps => {
	const {organization, contactInfo, footerLinks} = siteSettings;

	return {
		organization: {
			organizationNumber: organization?.number,
			address: contactInfo?.address,
			secondaryAddress: contactInfo?.secondaryAddress,
			phoneNumber: contactInfo?.phoneNumber,
			email: contactInfo?.email,
			name: organization?.name,
		},
		links: footerLinks?.map((link) => ({
			_key: link._key,
			href: linkFragmentToHref(link),
			title: link.title,
			targetBlank: link.targetBlank,
		})),
	};
};
