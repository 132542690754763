import type {SVGProps} from 'react';
export const PinIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
		<g
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			clipPath="url(#a)"
		>
			<path d="M19.8 8.8c0 4.2-3 6.6-5.2 9-1.3 1.4-2.3 6-2.3 6s-1.1-4.6-2.4-6c-2.2-2.4-5.2-4.8-5.2-9a7.5 7.5 0 0 1 15 0v0Z" />
			<path d="M12.3 11.8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="currentColor" d="M0 .5h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
