import type {FC} from 'react';
import clsx from 'clsx';
import Image from 'next/image';

import {Container} from '@/modules/foundation/components/container';
import {Link} from '@/modules/foundation/components/link';
import {createPhoneLink, formatPhoneNumber} from '@/modules/foundation/shared/format';
import type {KeyedArray} from '@/modules/foundation/shared/types';
import {CallIcon} from '@/modules/selvklart/components/icons/call-icon';
import {EnvelopeIcon} from '@/modules/selvklart/components/icons/envelope-icon';
import {PinIcon} from '@/modules/selvklart/components/icons/pin-icon';

type Address = {
	streetName?: string;
	postalCode?: string;
	city?: string;
};

type Organization = {
	organizationNumber?: string;
	address?: Address;
	secondaryAddress?: Address;
	phoneNumber?: string;
	email?: string;
	name?: string;
};

type FooterLink = {
	href?: string;
	title?: string;
	targetBlank?: boolean;
};

export type FooterProps = {
	organization?: Organization;
	links?: KeyedArray<FooterLink>;
};

export const Footer: FC<FooterProps> = ({links, organization}) => {
	const {address, secondaryAddress, phoneNumber, email, organizationNumber, name} =
		organization || {};

	return (
		<Container
			as="footer"
			className={clsx('py-[72px]', 'bg-primary', 'text-primary-light', 'text-lg')}
		>
			<Image
				aria-hidden
				src="/selvklart-logo-white.svg"
				alt=""
				width={160}
				height={52}
				className="mb-11"
			/>
			<div className={clsx('flex', 'justify-between', 'flex-wrap', 'gap-12')}>
				<div className={clsx('flex', 'flex-col', 'gap-3')}>
					{email && (
						<div className={clsx('flex', 'items-center', 'gap-3')}>
							<EnvelopeIcon width={25} aria-label="epost" className="shrink-0" />
							<Link
								href={`mailto:${email.replaceAll(' ', '')}`}
								className={clsx(
									'no-underline',
									'hover:underline',
									'text-primary-light',
								)}
							>
								{email}
							</Link>
						</div>
					)}

					{phoneNumber && (
						<div className={clsx('flex', 'items-center', 'gap-3')}>
							<CallIcon width={25} aria-label="telefon" className="shrink-0" />
							<Link
								href={createPhoneLink(phoneNumber)}
								className={clsx(
									'no-underline',
									'hover:underline',
									'text-primary-light',
								)}
							>
								{formatPhoneNumber(phoneNumber)}
							</Link>
						</div>
					)}

					{address && (
						<div className={clsx('flex', 'items-center', 'gap-3')}>
							<PinIcon width={25} aria-label="hovedadresse" className="shrink-0" />
							<p>
								<span>{address.streetName}</span>, <span>{address.postalCode}</span>{' '}
								<span>{address.city}</span>
							</p>
						</div>
					)}

					{secondaryAddress && (
						<div className={clsx('flex', 'items-center', 'gap-3')}>
							<PinIcon width={25} aria-label="adresse" className="shrink-0" />
							<p>
								<span>{secondaryAddress.streetName}</span>,{' '}
								<span>{secondaryAddress.postalCode}</span>{' '}
								<span>{secondaryAddress.city}</span>
							</p>
						</div>
					)}
				</div>

				{links?.length && (
					<ul className={clsx('flex', 'flex-col', 'gap-3')}>
						{links.map((link) => {
							return (
								<li key={link._key}>
									<Link
										href={link.href}
										targetBlank={link.targetBlank}
										className={clsx(
											'no-underline',
											'hover:underline',
											'text-primary-light',
										)}
									>
										{link.title}
									</Link>
								</li>
							);
						})}
					</ul>
				)}
			</div>
			{(name || organizationNumber) && (
				<p className={clsx('mt-12')}>
					{name}, org.nr. {organizationNumber}
				</p>
			)}
		</Container>
	);
};
