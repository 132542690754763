import type {SVGProps} from 'react';

export const EnvelopeIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="M21.8 4.5H2.1C1.4 4.5.8 5.2.8 6v12c0 .8.7 1.5 1.6 1.5h19.4c.9 0 1.6-.7 1.6-1.5V6c0-.8-.7-1.5-1.6-1.5ZM15.7 10l3.8 3.5M8.3 10l-3.8 3.5"
		/>
		<path
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.5}
			d="m22.9 5-9.5 6.6a2.4 2.4 0 0 1-2.8 0L1.1 5"
		/>
	</svg>
);
